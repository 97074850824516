import React from "react"

const initialStatsState = {
  IsDisplayingStats: false,
}
const statsReducer = (state, action) => {
  return {
    IsDisplayingStats: action,
  }
}

export const StatsContext = React.createContext()
export const StatsProvider = ({ children }) => {
  const [statsState, dispatchStatsState] = React.useReducer(
    statsReducer,
    initialStatsState
  )
  return (
    <StatsContext.Provider value={{ statsState, dispatchStatsState }}>
      {children}
    </StatsContext.Provider>
  )
}
