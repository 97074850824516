import React from "react"

const initialIntlState = {
  Intl: "en",
}
const intlReducer = (state, action) => {
  return {
    Intl: action,
  }
}

export const IntlContext = React.createContext()
export const IntlProvider = ({ children }) => {
  const [intlState, dispatchIntlState] = React.useReducer(
    intlReducer,
    initialIntlState
  )
  return (
    <IntlContext.Provider value={{ intlState, dispatchIntlState }}>
      {children}
    </IntlContext.Provider>
  )
}
