import React from "react"
import "./src/css/lib/css/stylesheet.css"
import "./src/css/lib/css/pledge.css"
import "./src/css/lib/fonts/fonts.css"

import { StatsProvider } from "./src/context/NextBackUsageProvider"
import { IntlProvider } from "./src/context/IntlProvider"

export const wrapRootElement = ({ element }) => {
  return (
    <IntlProvider>
      <StatsProvider>{element} </StatsProvider>
    </IntlProvider>
  )
}
